import { Image, Container, Flex, Button, Text } from '@chakra-ui/react'
import { useDesktop } from 'hooks/useDesktop'
import { useSaleGroup } from 'hooks/useSaleGroup'
import Link from 'next/link'

const BOSS_FIGHTER_SALE_SLUG = 'boss-fighters-alpha-sale'

export const BossFightersSale = () => {
  const { data: saleGroup } = useSaleGroup(BOSS_FIGHTER_SALE_SLUG)
  const url = `/sales/${BOSS_FIGHTER_SALE_SLUG}`

  const isDesktop = useDesktop()
  const background = isDesktop
    ? 'linear-gradient(180deg, rgba(0, 0, 0, 0) 60.09%, rgba(0, 0, 0, 0.98) 95%, #000000 100%), url(/images/boss-fighters/background-alpha-sale-desktop.png)'
    : 'linear-gradient(180deg, rgba(0, 0, 0, 0) 70.09%, #000000 95%, #000000 100%), url(/images/boss-fighters/background-alpha-sale-mobile.png)'

  const nowTimestamp = new Date().getTime()
  const availableAtDate = saleGroup ? new Date(saleGroup.availableAt) : null
  const isActiveSale = availableAtDate ? new Date(availableAtDate).getTime() <= nowTimestamp : true

  return (
    <Flex
      w="full"
      bgImage={background}
      minH={['753px']}
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
      backgroundSize="cover"
      mb={['0px', '-100px']}
      zIndex="0"
      position={['relative', 'unset']}
    >
      <Container maxW="container.lg" mb="10" color="white" mt="12">
        <Flex
          background="linear-gradient(180deg, #121828 0%, rgba(18, 24, 40, 0) 100%)"
          direction="column"
          borderLeftWidth="2px"
          borderRightWidth="2px"
          borderTopWidth="0px"
          borderBottomWidth="0px"
          backgroundPosition="0px 1px"
          alignItems="center"
          mt={['-16', '-20']}
          mx="auto"
          width="fit-content"
          minH={['300px', '408px']}
          pt={28}
          px={3}
          sx={{
            borderBottomWidth: '0px',
            borderStyle: 'solid',
            borderImage: `linear-gradient(
              to bottom, 
              rgba(255, 255, 255, 0.3),
              rgba(0, 0, 0, 0)
            ) 1 100%`,
          }}
        >
          <Image
            src="/images/boss-fighters/logo.png"
            alt="Boss Fighters Logo"
            w={['295px', '325px']}
            mb="10px"
          />
          <Text
            fontFamily="Montserrat"
            fontWeight="700"
            fontSize={{ base: '24px', md: '35px' }}
            lineHeight={{ base: '29.26px', md: '40px' }}
            textShadow="6px 6px 14px rgba(0, 0, 0, 0.85)"
            textAlign="center"
            mb="3"
          >
            Alpha Collection Sale
          </Text>
          <Text
            // w={{ base: '350px', md: 'auto' }}
            fontFamily="Bai Jamjuree"
            fontWeight="400"
            fontSize={{ base: '14px', md: '16px' }}
            lineHeight={{ base: '17.5px', md: '22px' }}
            textShadow="6px 6px 14px rgba(0, 0, 0, 0.85)"
            textAlign="center"
          >
            {isActiveSale
              ? "BOSS FIGHTERS' Alpha Passes are now available."
              : "BOSS FIGHTERS' Alpha Passes are coming soon!"}
          </Text>
          <Text
            // w={{ base: '350px', md: 'auto' }}
            fontFamily="Bai Jamjuree"
            fontWeight="700"
            fontSize={{ base: '14px', md: '16px' }}
            lineHeight={{ base: '17.5px', md: '22px' }}
            textShadow="6px 6px 14px rgba(0, 0, 0, 0.85)"
            textAlign="center"
            mb="6"
          >
            {isActiveSale
              ? 'Get yours before they’re sold out!'
              : 'Sale starts on August 24th at 12pm EST.'}
          </Text>
          <Link href={url} passHref legacyBehavior>
            <Button
              variant="light"
              boxShadow="2px 4px 8px rgba(0, 0, 0, 0.25)"
              borderRadius="full"
              size="md"
            >
              Go Now
            </Button>
          </Link>
        </Flex>
      </Container>
    </Flex>
  )
}
