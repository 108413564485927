import { Box, Flex, Heading, Input, Text } from '@chakra-ui/react'
import { Button } from 'components/atoms'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { useToast } from 'hooks/useToast'
import { NEWSLETTER_URL } from '../../../constants'

export const NewsLetter = () => {
  const toast = useToast()
  const [newsletterSubscribed, setNewsletterSubscribed] = useLocalStorage(
    'newsletter-subscribed',
    false
  )

  const handleForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const data = new FormData(e.target as HTMLFormElement)
    fetch(NEWSLETTER_URL, {
      method: 'POST',
      body: data,
      mode: 'no-cors',
    })
      .then(() => {
        setNewsletterSubscribed(true)
        toast({
          title: 'Success!',
          description: 'You have been subscribed to our newsletter.',
          status: 'success',
          isClosable: true,
        })
      })
      .catch(() => {
        toast({
          title: 'Oops!',
          description: 'Something went wrong. Please try again later.',
          status: 'warning',
        })
      })
  }

  return (
    <Box
      as="section"
      bgGradient="linear-gradient(263.19deg, #E5E5E5 44.67%, #FFFFFF 84.88%)"
      color="black"
      px={5}
      py={[10, null, 12, null, '60px']}
      textAlign="center"
    >
      <Box maxW={{ base: '420px', xl: 'unset' }} mx="auto">
        <Heading size="xl">Never miss a drop</Heading>
        <Text
          textAlign={{ base: 'start', lg: 'center' }}
          mt="18px"
          fontWeight="light"
          fontSize="16px"
        >
          Subscribe to our exclusive drop list and be the first to know about upcoming drops
        </Text>
        <Flex maxW="full" justifyContent="center">
          {newsletterSubscribed ? (
            <Text as="b" textAlign="center" fontWeight="normal">
              Already Subscribed
            </Text>
          ) : (
            <form onSubmit={(e) => handleForm(e)}>
              <Flex
                flexDir={{ base: 'column', md: 'row' }}
                mt="30px"
                w={{ lg: '372px', xl: '402px' }}
                mx="auto"
              >
                <Input
                  border="1px"
                  borderColor="blackAlpha.600"
                  borderRadius="none"
                  placeholder="Email Address"
                  _placeholder={{
                    color: 'input-placeholder',
                    fontStyle: 'italic',
                  }}
                  _hover={{
                    color: 'gray.500',
                  }}
                  color="black"
                  mr={{ md: 3 }}
                  name="email"
                  type="email"
                />
                <Button
                  mt={{ base: 3, md: 0 }}
                  size="auto"
                  px={2}
                  py={2}
                  variant="none"
                  bg="subscribe-btn"
                  color="white"
                  border="1px"
                  borderColor="whiteAlpha.200"
                  maxW={{ base: '103px', md: '111px' }}
                  mx="auto"
                  type="submit"
                >
                  Subscribe
                </Button>
              </Flex>
            </form>
          )}
        </Flex>
      </Box>
    </Box>
  )
}
