import { Box, Flex, Stack, Image, Text, Heading } from '@chakra-ui/react'
import { ButtonLink, ExternalLink } from 'components/atoms'
import { getHeroBannerDesktopBg, getHeroBannerGameLogo, getHeroBannerMobileBg } from 'lib/image'
import { HeroBanner } from 'types/vault/v2/HeroBanner'
import { Container } from '../layout'

export const GenericHeroBanner: React.FC<HeroBanner> = ({
  title,
  description,
  ctaActions,
  slug,
  game,
  logoUrl,
}) => {
  const mobileBg = getHeroBannerMobileBg(slug)
  const desktopBg = getHeroBannerDesktopBg(slug)

  return (
    <Box
      w="full"
      bgImage={{
        base: mobileBg,
        sm: desktopBg,
        '4xl': desktopBg,
      }}
      backgroundPosition="bottom center"
      backgroundSize="cover"
      zIndex="0"
      position={['relative', 'unset']}
    >
      <Container color="white" pb={{ base: '64px', sm: '54px', lg: '60px', '2xl': '66px' }}>
        <Flex
          h={{ base: '550px', sm: '416px', lg: '582px', '3xl': '720px', '4xl': '762px' }}
          flexDir="column"
          justifyContent="flex-end"
          textAlign={{ base: 'center', sm: 'left' }}
        >
          <Image
            src={logoUrl ?? (game ? getHeroBannerGameLogo(game.slug) : '')}
            alt={`${game ? game.name : ''} logo`}
            w={{
              base: '250px',
              sm: '200px',
              lg: '220px',
              xl: '243px',
              '2xl': '300px',
              '3xl': '337px',
            }}
            mx={{ base: 'auto', sm: 'unset' }}
          />

          <Box
            mx={{ base: 'auto', sm: 'unset' }}
            maxW={{ base: '400px', sm: '500px', lg: '650px', '2xl': '775px' }}
          >
            <Heading
              mt={{ base: '70px', sm: 5, lg: '30px' }}
              fontSize={{ base: '24px', xs: '30px', lg: '40px', '2xl': '50px' }}
              lineHeight={{ base: '30px', xs: '40px', sm: '37px', lg: '45px', '2xl': '55px' }}
              textShadow="6px 6px 14px rgba(0, 0, 0, 0.25)"
            >
              {title}
            </Heading>

            <Text
              mt={{ base: 2.5, sm: '14px' }}
              fontSize={{ base: '14px', sm: '16px', lg: '18px', xl: '20px' }}
              lineHeight={{ base: '18px', sm: '20px', lg: '22px', xl: '25px' }}
              maxW={{
                base: '295px',
                xs: '400px',
                sm: '450px',
                md: '500px',
                lg: '620px',
                xl: '718px',
              }}
              mx={{ base: 'auto', sm: 'unset' }}
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
          </Box>

          <Stack
            mt="24px"
            justifyContent={{ base: 'center', sm: 'start' }}
            alignItems={{ base: 'center', sm: 'start' }}
            direction={{ base: 'column', md: 'row' }}
          >
            {ctaActions.map(({ label, url, isExternal }) =>
              isExternal ? (
                <ExternalLink
                  key={url}
                  href={url}
                  label={label}
                  whiteSpace="break-spaces"
                  textAlign="center"
                  bg="white"
                  color="gray.800"
                  fontWeight="bold"
                  w="auto"
                  h="auto"
                  px={{ base: '26px', md: '40px', lg: '72px', xl: '105px' }} // TODO should use width??
                  py={{ base: '7px', lg: '12px', xl: '15px' }}
                  fontFamily="heading"
                  _hover={{
                    color: 'text-cards',
                    bg: 'whiteAlpha.700',
                  }}
                  className="swiper-no-swiping"
                />
              ) : (
                <ButtonLink
                  href={url}
                  key={url}
                  variant="light"
                  size="md"
                  whiteSpace="break-spaces"
                  textAlign="center"
                  className="swiper-no-swiping"
                  w="auto"
                  h="auto"
                  px={{ base: '26px', md: '40px', lg: '80px', xl: '105px' }}
                  py={{ base: '7px', lg: '12px', xl: '15px' }}
                >
                  {label}
                </ButtonLink>
              )
            )}
          </Stack>
        </Flex>
      </Container>
    </Box>
  )
}
