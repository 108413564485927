import { Box, Text, Heading, Flex, Image, useBreakpointValue, HStack } from '@chakra-ui/react'
import { Button, CountDown, Span } from 'components/atoms'
import { Container } from '../layout'

export const BigTimeSale = () => {
  // TODO optimize big size images either using next images or compressing manually
  const bgImage = useBreakpointValue({
    base: '/images/home/bigsale-bg.png',
    md: '/images/home/bigsale-bg-lg.png',
  })

  return (
    <Box
      bgImage={bgImage}
      bgPosition="top center"
      bgSize={{ base: '100%', md: 'cover' }}
      bgRepeat="no-repeat"
      pt={[8, 10, 12, 14, null, '68px']}
      pb={['23px', 7, null, 8, null, '43px']}
    >
      <Container>
        <Box as="section" px={{ base: 4, xs: 6, sm: 10, '3xl': '52px' }} pos="relative">
          <HStack spacing={{ lg: 10, xl: 20, '2xl': '122px' }}>
            <Flex
              flexDir={{ base: 'column', md: 'row' }}
              justifyContent={{ md: 'space-between' }}
              w="100%"
              bg="blackAlpha.800"
              p={[5, 7]}
            >
              <Box display={{ base: 'none', md: 'block' }} mr={{ md: 4 }}>
                <Heading size="xl" as="h4">
                  BIG TIME SALE
                </Heading>
                <Heading mt={{ md: 2, lg: 3 }} size="lg" as="h6">
                  Big Time
                </Heading>

                <Text size="sm" mt={{ md: 2, lg: 3 }} maxW="370px" color="gray.300">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus nunc, cursus in
                  odio viverra. At commodo volutpat faucibus id enim sit ut porta.
                </Text>
              </Box>
              <Box>
                <Box maxW="153px" mx="auto" textAlign="center">
                  <Heading
                    size="xl"
                    as="h4"
                    color="rarity.mythical"
                    fontSize="1.5rem"
                    whiteSpace="nowrap"
                  >
                    Available in
                  </Heading>

                  <CountDown />
                </Box>
                <Heading mt={['11px', 4]} size="xl" as="h4" display={{ md: 'none' }}>
                  Mystery Box MEDIUM
                </Heading>
                <Heading mt="11px" size="lg" as="h5" display={{ md: 'none' }}>
                  <Span size="inherit" mr={1}>
                    $
                  </Span>
                  <Span size="inherit">200</Span>
                </Heading>
                <Text size="sm" mt={['11px', 4]} maxW="370px" display={{ md: 'none' }}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus nunc, cursus in
                  odio viverra. At commodo volutpat faucibus id enim sit ut porta.
                </Text>
                <Button
                  mt={['11px', 4]}
                  size="full"
                  variant="light-outline"
                  minW={{
                    lg: '120px',
                    xl: '160px',
                    '2xl': '190px',
                    '3xl': '206px',
                  }}
                >
                  More Details
                </Button>
              </Box>
            </Flex>

            <Box
              display={{ base: 'none', lg: 'block' }}
              w="100%"
              maxW={{ lg: '200px', xl: '300px', '2xl': '350px', '3xl': '396px' }}
            >
              <Image src="/images/home/bigtime-sale.png" alt="big time sale logo" />
            </Box>
          </HStack>
        </Box>
      </Container>
    </Box>
  )
}
