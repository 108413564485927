import { Box, Text } from '@chakra-ui/react'
import { Button } from 'components/atoms'

export const GameSubmission = () => (
  <Box
    as="section"
    bg="white"
    color="black"
    px={5}
    py={[10, null, 12, null, '60px']}
    textAlign="center"
  >
    <Box maxW={['400px', null, '450px', null, '500px', '600px', '762px']} mx="auto">
      <Button
        mb={5}
        w="100%"
        h="40px"
        variant="highlight"
        maxW={{ base: '250px', lg: '270px', '2xl': '306px' }}
      >
        Submit your Game
      </Button>
      <Text textAlign="center" fontWeight="normal">
        <Text as="b">Open Loot</Text> is looking for partners who share our vision of web3 gaming.
      </Text>
      <Text textAlign="center" fontWeight="normal">
        Connect with us and let us know what you're working on.
      </Text>
    </Box>
  </Box>
)
