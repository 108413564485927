import { Image, Container, Flex, Text } from '@chakra-ui/react'
import { useFeature } from 'hooks/useFeature'
import { useDesktop } from 'hooks/useDesktop'
import { ButtonLink } from 'components/atoms'

export const EALHero = () => {
  const isRedeemLive = useFeature('redemptions')
  const isDesktop = useDesktop()
  const background = isDesktop
    ? 'linear-gradient(180deg, rgba(0, 0, 0, 0) 60.09%, rgba(0, 0, 0, 0.98) 95%, #000000 100%), url(/images/Bkgd.png)'
    : 'linear-gradient(180deg, rgba(0, 0, 0, 0) 70.09%, #000000 95%, #000000 100%), url(/images/Bkgd_mobile.png)'

  return (
    <Flex
      w="full"
      bgImage={background}
      minH={['753px']}
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
      backgroundSize="cover"
      mb={['0px', '-100px']}
      zIndex="0"
      position={['relative', 'unset']}
    >
      <Flex>
        <Image
          position="absolute"
          bottom="-20"
          src="/images/characters.png"
          alt="Big Time Charactes"
          w="99%"
          display={{ base: 'block', sm: 'none' }}
        />
      </Flex>
      <Container maxW="container.lg" mb="10" color="white" mt="12">
        <Image
          src="/images/eal_logo.png"
          alt="Big Time Early Access"
          w="full"
          display={{ base: 'none', sm: 'block' }}
        />
        <Image
          src="/images/eal_logo-mobile.png"
          alt="Big Time Early Access"
          w="full"
          display={{ base: 'block', sm: 'none' }}
          transform="scale(1.1)"
        />
        <Flex
          background="linear-gradient(180deg, #121828 0%, rgba(18, 24, 40, 0) 100%)"
          direction="column"
          borderLeftWidth="2px"
          borderRightWidth="2px"
          borderTopWidth="0px"
          borderBottomWidth="0px"
          backgroundPosition="0px 1px"
          alignItems="center"
          mt={['-16', '-20']}
          mx="auto"
          width="fit-content"
          minH={['300px', '408px']}
          pt="28"
          px={['3', '10', '16']}
          sx={{
            borderBottomWidth: '0px',
            borderStyle: 'solid',
            borderImage: `linear-gradient(
                to bottom, 
                white, 
                rgba(0, 0, 0, 0)
              ) 1 100%`,
          }}
        >
          <Text
            fontWeight="bold"
            fontSize={['xl', 'xl', '2xl']}
            textShadow="6px 6px 14px rgba(0, 0, 0, 0.85)"
            textAlign="center"
            mb="6"
          >
            Early Access
            <br />
            is now available
            <br />
            for Ruby Pass
          </Text>
          {isRedeemLive ? (
            <ButtonLink
              href="/games/big-time"
              colorScheme="white"
              background="white"
              transition="none"
              fontWeight="bold"
              _hover={{
                background: 'orange.400',
              }}
              boxShadow="2px 4px 8px rgba(0, 0, 0, 0.25)"
              borderRadius="0px"
              color="black"
              size="md"
            >
              Go Now
            </ButtonLink>
          ) : (
            <ButtonLink
              href="https://www.youtube.com/watch?v=FlRz-p9v8hM"
              colorScheme="white"
              background="white"
              transition="none"
              fontWeight="bold"
              _hover={{
                background: 'orange.400',
              }}
              boxShadow="2px 4px 8px rgba(0, 0, 0, 0.25)"
              borderRadius="0px"
              color="black"
              size="lg"
            >
              Watch Trailer
            </ButtonLink>
          )}
        </Flex>
      </Container>
    </Flex>
  )
}
