import { Image, Container, Flex, Text } from '@chakra-ui/react'
import { ButtonLink } from 'components/atoms'
import { useDesktop } from 'hooks/useDesktop'

export const BossFighters = () => {
  const url =
    'https://lp.bossfighters.game/alpha-passes?utm_source=openloot&utm_campaign=alpha-passes&utm_medium=banner'
  const isDesktop = useDesktop()
  const background = isDesktop
    ? 'linear-gradient(180deg, rgba(0, 0, 0, 0) 60.09%, rgba(0, 0, 0, 0.98) 95%, #000000 100%), url(/images/boss-fighters/background-desktop.png)'
    : 'linear-gradient(180deg, rgba(0, 0, 0, 0) 70.09%, #000000 95%, #000000 100%), url(/images/boss-fighters/background-mobile.png)'

  return (
    <Flex
      w="full"
      bgImage={background}
      minH={['753px']}
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
      backgroundSize="cover"
      mb={['0px', '-100px']}
      zIndex="0"
      position={['relative', 'unset']}
    >
      <Container maxW="container.lg" mb="10" color="white" mt="12">
        <Flex
          background="linear-gradient(180deg, #121828 0%, rgba(18, 24, 40, 0) 100%)"
          direction="column"
          borderLeftWidth="2px"
          borderRightWidth="2px"
          borderTopWidth="0px"
          borderBottomWidth="0px"
          backgroundPosition="0px 1px"
          alignItems="center"
          mt={['-16', '-20']}
          mx="auto"
          width="fit-content"
          minH={['300px', '408px']}
          pt={28}
          px={3}
          sx={{
            borderBottomWidth: '0px',
            borderStyle: 'solid',
            borderImage: `linear-gradient(
              to bottom, 
              rgba(255, 255, 255, 0.3),
              rgba(0, 0, 0, 0)
            ) 1 100%`,
          }}
        >
          <Image
            src="/images/boss-fighters/logo.png"
            alt="Boss Fighters Logo"
            w={['295px', '325px']}
            mb="10px"
          />
          <Text
            fontFamily="Montserrat"
            fontWeight="700"
            fontSize={{ base: '24px', md: '35px' }}
            lineHeight={{ base: '29.26px', md: '40px' }}
            textShadow="6px 6px 14px rgba(0, 0, 0, 0.85)"
            textAlign="center"
            mb="3"
          >
            Get your Alpha Pass
          </Text>
          <Text
            // w={{ base: '350px', md: 'auto' }}
            fontFamily="Bai Jamjuree"
            fontWeight="400"
            fontSize={{ base: '14px', md: '16px' }}
            lineHeight={{ base: '17.5px', md: '22px' }}
            textShadow="6px 6px 14px rgba(0, 0, 0, 0.85)"
            textAlign="center"
          >
            BOSS FIGHTERS' Alpha Skins collection is coming soon to Open Loot.
          </Text>
          <Text
            fontFamily="Bai Jamjuree"
            fontWeight="700"
            fontSize={{ base: '14px', md: '16px' }}
            lineHeight={{ base: '17.5px', md: '22px' }}
            textShadow="6px 6px 14px rgba(0, 0, 0, 0.85)"
            textAlign="center"
            mb="6"
          >
            Limited spots available.
          </Text>
          <ButtonLink
            href={url}
            target="_blank"
            variant="light"
            boxShadow="2px 4px 8px rgba(0, 0, 0, 0.25)"
            borderRadius="full"
            size="md"
          >
            Secure yours now!
          </ButtonLink>
        </Flex>
      </Container>
    </Flex>
  )
}
