import { Box, Text, Flex } from '@chakra-ui/react'
import { ButtonLink } from 'components/atoms'
import Image from 'next/legacy/image'
import { DEFAULT_MARKETPLACE_ROUTE } from '../../../constants'

export const ExploreMarket = () => (
  <Flex
    position="relative"
    py={['55px', null, '60px', null, 18, 20, '116px']}
    px="22px"
    textAlign="center"
  >
    <Image
      src="/images/home/explore-market-bg-v2.png"
      alt="Background Image"
      layout="fill"
      quality={100}
    />
    <Box maxW={['400px', null, '450px', null, '500px', '600px', '762px']} mx="auto" zIndex="1">
      <Text
        fontWeight="700"
        fontSize={{ base: '24px', md: '45px' }}
        lineHeight={{ base: '29px', md: '50px' }}
      >
        Check out our open market and trade with others!
      </Text>

      <ButtonLink
        href={DEFAULT_MARKETPLACE_ROUTE}
        mt={10}
        variant="light"
        w="100%"
        maxW={{ base: '250px', lg: '270px', '2xl': '306px' }}
      >
        Explore Market
      </ButtonLink>
    </Box>
  </Flex>
)
