import { Box, Center, Circle, Flex, Heading, HStack, Image, Text } from '@chakra-ui/react'
import { Alert, Button, Span } from 'components/atoms'
import Link from 'next/link'
import { Container } from '../layout'

export const Hero = () => (
  <Container mt={[2.5, 4, 7, 8, '34px']}>
    {/* TODO: determine whether this alert should be here and add action */}
    <Box mb="50px">
      <Alert
        label={
          <Text size="sm">
            It seems your are using a VPN access. To make any further transaction on the
            Marketplace, you will need to make{' '}
            <Link href="/#" passHref legacyBehavior>
              <Span textDecoration="underline" fontSize="14px" lineHeight="16px" cursor="pointer">
                Identity Verification
              </Span>
            </Link>
          </Text>
        }
      />
    </Box>

    <Flex
      px={{ lg: 4, xl: 6, '2xl': '30px' }}
      flexDir={{
        base: 'column',
        lg: 'row-reverse',
      }}
      alignItems={{ lg: 'center' }}
      pos="relative"
      maxW={{ lg: '1024px', xl: '1120px', '2xl': '1280px', '3xl': 'none' }}
      mx="auto"
    >
      <Box
        flex={1}
        minW={{ lg: '450px', xl: '370px', '3xl': '524px' }}
        mx={[1.5, 3, 6, null, 0]}
        px={[6, 7, null, null, 0]}
        pos={{ base: 'relative', lg: 'static' }}
        ml={{ lg: 4, xl: 9 }}
      >
        <Button variant="none" size="auto" pos="absolute" top="43%" left={{ base: 0, '2xl': 2 }}>
          <Image
            src="/images/icons/chevron-left.svg"
            alt="chevron left icon"
            w={['auto', 2.5, null, 3, null, '18px']}
          />
        </Button>

        <Button variant="none" size="auto" pos="absolute" top="43%" right={0}>
          <Image
            src="/images/icons/chevron-right.svg"
            alt="chevron right icon"
            w={['auto', 2.5, null, 3, null, '18px']}
          />
        </Button>

        <Heading
          size="xxl"
          textShadow="heroText"
          maxW={['295px', null, '340px', null, '400px', '450px', null, '500px']}
        >
          Neon City Exalted Space Sale!
        </Heading>
        <Text
          size="lg"
          mt={{ base: 3.5, lg: 2.5, xl: 3, '2xl': '14px' }}
          maxW={{
            base: '560px',
            '2xl': '598px',
          }}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus nunc, cursus in odio
          viverra.{' '}
          <Span fontWeight="bold" size="lg">
            At commodo volutpat faucibus id enim sit ut porta.
          </Span>
        </Text>
        <HStack
          mt={{ base: 9, lg: 4, xl: 7, '2xl': 9 }}
          spacing={{ base: '15px', sm: 6, lg: '15px', '2xl': '30px' }}
        >
          <Button
            maxW={{ base: '250px', lg: '200px', '2xl': '306px' }}
            flex={1}
            variant="light"
            py="15px"
            textTransform="uppercase"
            boxShadow="heroText"
          >
            GET MINE
          </Button>
          <Button
            maxW={{ base: '250px', lg: '200px', '2xl': '306px' }}
            flex={1}
            variant="light-outline"
            py="15px"
            boxShadow="heroText"
          >
            Explore
          </Button>
        </HStack>
        <HStack justify="center" mt="35px" spacing={2.5} display={{ base: 'flex', lg: 'none' }}>
          <Button size="auto" variant="none">
            <Circle size="10px" bg="bright.default" />
          </Button>
          <Button size="auto" variant="none">
            <Circle size="10px" bg="bright.200" />
          </Button>
          <Button size="auto" variant="none">
            <Circle size="10px" bg="bright.200" />
          </Button>
        </HStack>
      </Box>

      <Center
        minW={{ lg: '330px', xl: '380px', '2xl': '460px', '3xl': '524px' }}
        mt={{ base: '25px', lg: 0 }}
        px={2.5}
        py={{ lg: '18px' }}
        h={['315px', null, '380px', '420px', '260px', '300px', '333px']}
        bg="blackAlpha.600"
        borderTopLeftRadius="50px"
        borderBottomRightRadius="50px"
      >
        <Image
          src="/images/home/hero-card.png"
          alt="polygon shaped card"
          width="100%"
          maxW={{
            base: '290px',
            md: '350px',
            lg: '200px',
            xl: '240px',
            '2xl': '260px',
            '3xl': '265px',
          }}
        />
      </Center>
    </Flex>

    {/* // TODO make reuseable component when adding carousel logic */}
    <HStack
      justify="center"
      mt={{ lg: '35px', xl: 12, '2xl': '60px' }}
      spacing={2.5}
      display={{ base: 'none', lg: 'flex' }}
    >
      <Button size="auto" variant="none">
        <Circle size="10px" bg="bright.default" />
      </Button>
      <Button size="auto" variant="none">
        <Circle size="10px" bg="bright.200" />
      </Button>
      <Button size="auto" variant="none">
        <Circle size="10px" bg="bright.200" />
      </Button>
    </HStack>
  </Container>
)
