import { Box, Heading, Text, Flex, useMediaQuery, Grid } from '@chakra-ui/react'
import { Select, Option, Span } from 'components/atoms'
import { useOpenlootStats } from 'hooks/useOpenlootStats'
import { useState } from 'react'
import { formatNumber } from 'lib/format'

const Stats = ({
  value,
  label,
  isCurrency,
}: {
  value: number
  label: string
  isCurrency?: boolean
}) => {
  const formattedNumber = formatNumber(Number(value ? value.toFixed(2) : 0))
  const displayNumber = isCurrency ? `$${formattedNumber}` : formattedNumber
  return (
    <Flex
      w="full"
      h="auto"
      px="12px"
      py="12px"
      border="1px solid rgba(255, 255, 255, 0.40)"
      boxShadow="md"
      borderRadius="lg"
      alignItems={{ base: 'stretch', md: 'center' }}
      direction={{ base: 'column', md: 'column' }}
    >
      <Text
        size="base"
        color="gray.500"
        fontSize="16px"
        lineHeight="16px"
        marginRight="auto"
        mb={3}
      >
        {label}
      </Text>
      <Heading as="span" fontSize="24px" lineHeight="24px" fontWeight="bold" marginRight="auto">
        {displayNumber}
      </Heading>
    </Flex>
  )
}

const TimeFrameOptions = [
  { id: '24h', label: 'Last 24 Hours' },
  { id: '7d', label: 'Last 7 Days' },
  { id: '30d', label: 'Last 30 Days' },
  { id: '1y', label: 'Last Year' },
]

export const SelectTimeFrame = ({
  value,
  onChange,
}: {
  value: string
  onChange: (value: string) => void
}) => (
  <Select
    w={{ base: 'full', xs: '200px' }}
    color="gray.500"
    value={value}
    fontSize="14px"
    onChange={(e: any) => onChange(e.target.value)}
    borderColor="title-fields"
  >
    {TimeFrameOptions.map(({ id, label }) => (
      <Option key={id} value={id}>
        {label}
      </Option>
    ))}
  </Select>
)

export const OpenlootStats = () => {
  const { data } = useOpenlootStats()
  const [timeframe, setTimeframe] = useState('30d')
  const [isMobile] = useMediaQuery('(max-width: 820px)')
  const stats = data?.find((s) => s.timeframe === timeframe)

  if (!stats) {
    return null
  }

  return (
    <Box as="section" my={20} p={4}>
      <Flex w="100%" mb={4} direction="column">
        <Grid
          templateColumns={isMobile ? '1fr' : '1fr auto 1fr'} // Single column on mobile, three columns on larger screens
          alignItems="center"
          mb={4}
          w="100%"
          gap={4} // Add some space between items on mobile
        >
          <Box />
          <Span textAlign="center" w="full">
            <Heading as="h3" size="xl" textShadow="heroText" textAlign="center" flex="1">
              Marketplace Activity
            </Heading>
          </Span>
          <Box justifySelf={isMobile ? 'center' : 'end'} w="auto">
            <SelectTimeFrame value={timeframe} onChange={setTimeframe} />
          </Box>
        </Grid>
        <Grid
          templateColumns={{ base: '1fr', sm: '1fr 1fr', lg: 'repeat(4, 1fr)' }} // Single column on mobile, two columns on tablet, four on desktop
          gap={4}
          mt={8}
        >
          <Stats label="Transactions" value={stats.transactionsCount} />
          <Stats label="Total Volume" value={stats.transactionsUSDVolume} isCurrency />
          <Stats label="NFTs Purchased" value={stats.itemsVolume} />
          <Stats label="NFTs Rented" value={stats.rentalsVolume} />
        </Grid>
      </Flex>
    </Box>
  )
}
